import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1440,
    width: '100%',
    margin: '0 auto',
    backgroundColor: 'transparent',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    textTransform: 'capitalize'
  },
  hidden: {display: 'none'},
  toolbar: {
    maxWidth: 1300,
    width: '100%',
    minHeight: 80,
    margin: '0 auto',
    padding: 0,
    gap: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {}
  },
  authButtons: {
    display: 'flex',
    gap: theme.spacing(1.5),
    whiteSpace: 'nowrap'
  },
  rightSideItems: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  }
}));
