import {Toolbar} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import useModal from 'src/hooks/useModal';
import useStorefrontData from 'src/hooks/useStorefrontData';
import {CurrencyEnum} from 'src/core/enums';
import {LOGIN_MODAL} from 'src/config';

import {useStyles} from './styled';

import {DesktopMenuItems} from 'src/views/HeaderNext/components/DesktopMenu/DesktopMenuItems';
import {ProfileInfoWithDropdown} from 'src/views/HeaderNext/components/DesktopMenu/ProfileInfoWithDropdown';
import {SignUpButton} from 'src/views/HeaderNext/components/SignUpButton';
import {LoginButton} from 'src/views/HeaderNext/components/LoginButton';
import {CurrencySwitcher} from 'src/views/HeaderNext/components/CurrencySwitcher';
import {Logo} from 'src/views/Header/components/Logo';

export const DesktopMenu = ({menu, customer, isHidden = false}) => {
  const classes = useStyles();
  const {showModal} = useModal();

  const {currency, updateCurrency} = useStorefrontData();
  const currencies = [];
  Object.values(CurrencyEnum).forEach(value => {
    currencies.push(value);
  });

  const handleChangeCurrency = value => {
    updateCurrency(value);
  };

  return (
    <div className={clsx(classes.root, isHidden && classes.hidden)}>
      <Toolbar className={classes.toolbar}>
        <Logo />
        <DesktopMenuItems menu={menu} customer={customer} />
        <div className={classes.rightSideItems}>
          {customer !== undefined && (
            <>
              <CurrencySwitcher
                currency={currency}
                currencies={currencies}
                onChange={handleChangeCurrency}
              />

              {customer && (
                <div>
                  <ProfileInfoWithDropdown customer={customer} />
                </div>
              )}
              {customer === null && (
                <div className={classes.authButtons}>
                  <LoginButton onClick={() => showModal(LOGIN_MODAL)} />
                  <SignUpButton />
                </div>
              )}
            </>
          )}
        </div>
      </Toolbar>
    </div>
  );
};

DesktopMenu.propTypes = {
  menu: PropTypes.object,
  customer: PropTypes.object,
  isHidden: PropTypes.bool
};
